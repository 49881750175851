<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>图片素材</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div v-loading="loading_img"
             element-loading-text="图片上传中"
             class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-upload
                                v-if="is_auth('it.material.imgadd')"
                                :show-file-list="false"
                                :action="url"
                                :before-upload="getUploadtoken"
                        >
                            <el-button icon="el-icon-upload" size="medium" type="primary">点击上传</el-button>
                        </el-upload>


                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium">
                <el-table-column
                        prop="url"
                        label="图片"
                        width="100">
                    <template slot-scope="scope">
                        <viewer>
                            <el-avatar shape="square" :size="22" :src="scope.row.url"></el-avatar>
                        </viewer>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="url"
                        label="url">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="100">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('it.material.imgdel')"
                                type="danger"
                                @click="isdel(scope.row.id)"
                                size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import env from '/config/env'
import apiurl from '/config/apiurl'
import axios from "axios";

export default {
    data() {
        return {
            url: env.API_URL + apiurl.oss.url,
            page_name: '图片素材',
            loading: true,
            loading_img: false,
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取token
        getUploadtoken(file) {
            // console.log('上传内容', file)
            this.loading_img = true
            // 获取上传token
            let postData = {
                api_name: 'home.upload.gettoken',
                token: this.Tool.get_l_cache('token'),
                name: file.name,
                type: "image",
                catalog: "materialimg",
            }
            this.Tool.post_data('oss', postData, (json) => {
                if (json.code === 0) {
                    this.upqiniu(json.data, file)
                } else {
                    this.$message.error(json.message);
                }
            })

        },
        // 上传
        upqiniu(tokeninfo, file) {
            // console.log('文件信息',file)

            // 判断文件大小
            const issize = file.size / 1024 / 1024 < tokeninfo.size
            if (!issize) {
                this.$message.warning('不能超过 ' + tokeninfo.size + 'MB')
                return false
            }

            const formdata = new FormData()
            formdata.append('file', file)
            formdata.append('token', tokeninfo.token)
            formdata.append('key', tokeninfo.kyename)
            const headers = {
                headers: {'Content-Type': 'multipart/form-data'}
            }

            // 获取到凭证之后再将文件上传到七牛云空间
            axios.post(tokeninfo.uploadurl, formdata, headers).then(res => {
                let url = tokeninfo.domain + res.data.key
                if (tokeninfo.imgsize != null) {
                    url += tokeninfo.imgsize.compress
                }
                console.log('上传文件地址', url)
                this.imgadd(url)
            })
        },
        // 图片上传
        imgadd(url) {
            let postdata = {
                api_name: "it.material.imgadd",
                token: this.Tool.get_l_cache('token'),
                url: url,
            }
            // console.log('上传', postdata)
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading_img = false
                if (json.code === 0) {
                    this.init()
                }
            })
        },
        // 删除素材
        isdel(id) {
            // 弹出二次确认
            this.$confirm('确定删除么？', '确认信息',)
                    .then(() => {
                        this.isdels(id)
                    })
                    .catch(() => {
                    });
        },
        isdels(id) {
            let postdata = {
                api_name: "it.material.imgdel",
                token: this.Tool.get_l_cache('token'),
                id,
            }
            // console.log('上传', postdata)
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.init()
                }
            })
        },
        // 初始化
        init() {
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "it.material.imglist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
